import React /*, { SVGProps }*/ from 'react';

// export const TwitterIconSVG = (): SVGProps<SVGPathElement> => (
//     <svg
//         xmlns="http://www.w3.org/2000/svg"
//         xmlnsXlink="http://www.w3.org/1999/xlink"
//         viewBox="0 0 48 48"
//         width="48px"
//         height="48px"
//     >
//         <g id="surface307225">
//             <path
//                 style="stroke:none;fill-rule:nonzero;fill:rgb(79.215688%,23.529412%,23.921569%);fill-opacity:1;"
//                 d="M 42 12.429688 C 40.675781 13.015625 39.253906 13.40625 37.753906 13.589844 C 39.277344 12.683594 40.453125 11.238281 41.003906 9.53125 C 39.574219 10.371094 37.992188 10.984375 36.3125 11.308594 C 34.96875 9.882812 33.050781 9 30.925781 9 C 26.847656 9 23.539062 12.277344 23.539062 16.320312 C 23.539062 16.890625 23.605469 17.449219 23.730469 17.988281 C 17.59375 17.683594 12.148438 14.765625 8.507812 10.335938 C 7.867188 11.417969 7.507812 12.683594 7.507812 14.023438 C 7.507812 16.5625 8.808594 18.800781 10.792969 20.117188 C 9.582031 20.082031 8.441406 19.742188 7.445312 19.203125 C 7.445312 19.226562 7.445312 19.257812 7.445312 19.289062 C 7.445312 22.839844 9.992188 25.796875 13.367188 26.472656 C 12.75 26.640625 12.097656 26.734375 11.425781 26.734375 C 10.949219 26.734375 10.484375 26.679688 10.035156 26.597656 C 10.972656 29.5 13.699219 31.621094 16.933594 31.683594 C 14.402344 33.644531 11.21875 34.820312 7.757812 34.820312 C 7.160156 34.820312 6.574219 34.785156 5.996094 34.714844 C 9.269531 36.785156 13.152344 38 17.320312 38 C 30.90625 38 38.339844 26.84375 38.339844 17.164062 C 38.339844 16.847656 38.328125 16.53125 38.3125 16.222656 C 39.761719 15.195312 41.011719 13.90625 42 12.429688 "
//             />
//         </g>
//     </svg>
// );

type SVGProps = {
    className?: string;
    color?: string;
    size?: number;
};

export const TwitterIconSVG: React.FC<SVGProps> = (props) => {
    const { color, size, className } = props;
    return (
        <svg viewBox={'0 0 48 48'} width={size} height={size} className={className}>
            <path
                fill={color}
                d={`M 42 12.429688 C 40.675781 13.015625 39.253906 13.40625 37.753906 13.589844 C 39.277344 12.683594 40.453125 11.238281 41.003906 9.53125 C 39.574219 10.371094 37.992188 10.984375 36.3125 11.308594 C 34.96875 9.882812 33.050781 9 30.925781 9 C 26.847656 9 23.539062 12.277344 23.539062 16.320312 C 23.539062 16.890625 23.605469 17.449219 23.730469 17.988281 C 17.59375 17.683594 12.148438 14.765625 8.507812 10.335938 C 7.867188 11.417969 7.507812 12.683594 7.507812 14.023438 C 7.507812 16.5625 8.808594 18.800781 10.792969 20.117188 C 9.582031 20.082031 8.441406 19.742188 7.445312 19.203125 C 7.445312 19.226562 7.445312 19.257812 7.445312 19.289062 C 7.445312 22.839844 9.992188 25.796875 13.367188 26.472656 C 12.75 26.640625 12.097656 26.734375 11.425781 26.734375 C 10.949219 26.734375 10.484375 26.679688 10.035156 26.597656 C 10.972656 29.5 13.699219 31.621094 16.933594 31.683594 C 14.402344 33.644531 11.21875 34.820312 7.757812 34.820312 C 7.160156 34.820312 6.574219 34.785156 5.996094 34.714844 C 9.269531 36.785156 13.152344 38 17.320312 38 C 30.90625 38 38.339844 26.84375 38.339844 17.164062 C 38.339844 16.847656 38.328125 16.53125 38.3125 16.222656 C 39.761719 15.195312 41.011719 13.90625 42 12.429688`}
            />
        </svg>
    );
};
